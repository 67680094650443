import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {  Icon, Result, Spin } from 'antd';
import axios from 'axios';

import { processTransactionDetails } from '../../helpers/helpers';

import { API_URL } from '../../config';

import style from './style.module.css';

const ProcessQuery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const processQueryParams = async () => {
      setLoading(true);

      try {
        const params = new URLSearchParams(location.search);

        const code = params.get('code');

        if (!code) {
          throw new Error('Missing transaction details');
        }

        const transactionDetails = processTransactionDetails(code)

        if (transactionDetails.length < 7) {
          throw new Error('Transaction details is not complete');
        }

        const [posId] = transactionDetails

        const { data } = await axios.get(
          `${API_URL}v2/api/locations/pos/${posId}`
        );

        history.replace(`/${data.locationId}?code=${code}`);
      } catch (err) {
        console.log('Received error:', err);

        setError(true);
      } finally {
        setLoading(false);
      }
    };

    processQueryParams();
  }, [location.search, history]);

  if (loading) {
    return (
      <Spin
        indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        tip="Processing the transaction details..."
        className={style.loader}
      />
    )
  }

  if (error) {
    return (
       <Result
         status="404"
         title="Something went wrong"
         subTitle={'An error occurred while attempting to evaluate the transaction details.'}
         className={style.errorBox}
       />
    )
  }

  return null;
};

export default ProcessQuery;
